import React, { useState, useRef } from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { useSpring, animated, useChain } from "react-spring";
import { Link } from "gatsby";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import data from "../../../site/content/index.json";

import { device, ColorTypes } from "../../utils";
// @ts-expect-error
import imgL2HeroBg from "../../assets/image/home/hero.jpg";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf";

interface ThemeProps {
  theme: DefaultTheme;
  color: keyof ColorTypes;
}

const TopCard = styled(Box)`
  width: 360px;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
  position: absolute;
  top: 10%;
  z-index: 4;
  @media ${device.md} {
    left: -13%;
  }
  @media ${device.lg} {
    left: -15%;
  }
`;

const BottomCard = styled(Box)`
  padding: 8px;
  width: 305px;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
  position: absolute;
  bottom: 12%;
  right: 0;
  @media ${device.md} {
    right: -13%;
  }
  @media ${device.lg} {
    right: -5%;
  }
`;

const Phone = styled(animated.div)`
  position: relative;
  overflow: hidden;
  @media ${device.sm} {
    max-width: 70%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;
const SectionStyled = styled(Section)`
  position: relative;
  @media (min-width: 768px) {
    background: url(${imgL2HeroBg}) no-repeat;
    background-size: contain;
    background-position: bottom right;
  }
`;

const HeadImage = styled.img`
  width: 55px;
  height: 55px;
`;

const PhoneContainer = styled.div`
  display: inline-block;
  position: relative;
  right: 6%;
  overflow: hidden;
  width: 110%;
`;

const ImageContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const ProductImage = styled.img`
  height: 40px;
  margin-bottom: 20px;
`

const Hero = () => {
  const box1Ref = useRef();
  const box2Ref = useRef();

  const box1 = useSpring({
    opacity: 0.9,
    right: "-10%",
    from: { opacity: 0, right: "-100%" },
    ref: box1Ref,
    config: {
      duration: 400,
    },
  });
  const box2 = useSpring({
    opacity: 0.9,
    left: "-15%",
    from: { opacity: 0, left: "-100%" },
    ref: box2Ref,
  });

  useChain([box2Ref, box1Ref], [1, 2]);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="4" md="8" sm="9" className="order-lg-2">
              <ImageContainer className="text-center text-lg-right position-relative">
                <Phone>
                  <PhoneContainer>
                    <img
                      src={data.hero.image.image}
                      alt={data.hero.image.alt}
                    />
                  </PhoneContainer>
                </Phone>

                <TopCard
                  style={box2}
                  bg="secondary"
                  p="18px"
                  borderRadius={8}
                  className="d-flex align-items-center"
                >
                  <Box
                    width="35px"
                    minWidth="35px"
                    height="35px"
                    minHeight="35px"
                    bg="warning"
                    color="light"
                    borderRadius="50%"
                    className="d-flex align-items-center justify-content-center"
                    mr={3}
                  >
                    <HeadImage
                      src={data.hero.cards[0].image}
                      alt={data.hero.cards[0].alt}
                    />
                  </Box>
                  <Text
                    color="light"
                    className="text-left"
                    fontSize={2}
                    lineHeight="1.25"
                    ml={2}
                  >
                    {data.hero.cards[0].text}
                  </Text>
                </TopCard>

                <BottomCard
                  style={box1}
                  bg="warning"
                  p="18px"
                  borderRadius={8}
                  className="d-flex align-items-center justify-content-end text-left"
                >
                  <Text color="light" fontSize={2} lineHeight="1.25" ml={2}>
                    {data.hero.cards[1].text}
                  </Text>
                  <Box
                    width="55px"
                    minWidth="55px"
                    height="55px"
                    minHeight="55px"
                    bg="secondary"
                    color="light"
                    borderRadius="50%"
                    className="d-flex align-items-center justify-content-center"
                    ml={3}
                  >
                    <HeadImage
                      src={data.hero.cards[1].image}
                      alt={data.hero.cards[1].alt}
                    />
                  </Box>
                </BottomCard>
              </ImageContainer>
            </Col>
            <Col lg="8" className="order-lg-1">
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                <ProductImage  src="/assets/smart-radio-mega.png" />
                <Title variant="hero">
                  A Modern Communication Solution. 
                </Title>
                <div className="d-flex align-items-start flex-wrap">
                  <a href={pdfBrochure} download>
                    <Button mb={2}>Download our brochure</Button>
                  </a>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
