import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../sections/smart-radio/Hero2";
import Feature from "../sections/smart-radio/Feature";
import Content1 from "../sections/smart-radio/Content1";
import Content2 from "../sections/smart-radio/Content2";
import Content3 from "../sections/smart-radio/Content3";
import Content5 from "../sections/smart-radio/Content5";
import Content6 from "../sections/smart-radio/Content6";
import CTA from "../sections/smart-radio/CTA";
import PageWrapper from "../components/PageWrapper";

const LandingPage4 = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Smart Radio</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero />
        <Feature />
        <Content6 />
        <Content2 />
        <Content3 />
        <Content1 />
        <Content5 />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage4;
